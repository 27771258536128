@import "../../variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

.image-component {
    margin: 0;
    width: 100%;
    position: relative;
}

.common-image-component {
    width: 100%;
    object-fit: cover;
    object-position: var(--focal-point-x) var(--focal-point-y);
}

.mainbanner-image {
    height: 43.75vw;
    @include media-breakpoint-down(xs) {
        height: 75vw;
    }
}

.common-image-filter {
    filter: brightness(40%);
}

.image-heading-container {
    position: absolute;
    top: 50%;
    width: 100%;
    @include media-breakpoint-up(sm) {
        padding-left: 7%;
    }
}

.image-heading-text {
    font-family: $image-text-font;
    color: $white-text;
}

.ITC-image-heading-text {
    color: $banner-heading-text-color;
}

.image-heading-container .mainbanner-sub-text p {
    font-size: $banner-sub-text-font-size;
    font-family: $banner-font;
    color: $banner-sub-text-color;
    text-decoration: underline;
}

.ITC-text-underneath span {
    color: $black;
    padding-top: 0.75em;
    text-align: center;
}
